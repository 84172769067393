/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
import { Controller } from 'react-hook-form';
import { FieldError, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { components } from 'react-select';

import { Container, ReactSelect, ErrorWrapper } from './styles';

type SelectOption = { value: string; label: string };

export type SelectProps = {
  name: string;
  placeholder: string;
  required?: boolean;
  isMulti?: boolean;
  options: Array<SelectOption>;
  // onChange?: (...event: any[]) => void;
};

const MultiSelect = ({
  name,
  placeholder,
  options,
  required = false,
  isMulti = false,
}: SelectProps) => {
  const { colors } = useTheme();
  const { control, formState } = useFormContext();
  const inputError = formState.errors[name] as FieldError;

  const Option = (props: any) => (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          // eslint-disable-next-line react/destructuring-assignment
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );

  return (
    <Container>
      <Controller
        name={name}
        defaultValue={isMulti ? [] : ''}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <ReactSelect
            {...field}
            $error={!!inputError}
            hideSelectedOptions={false}
            menuPortalTarget={document.querySelector('#root')}
            options={options}
            isMulti={isMulti}
            components={{
              Option,
            }}
            closeMenuOnSelect={!isMulti}
            placeholder={placeholder}
            maxMenuHeight={250}
            // value={options.find((option) => option.value === field.value)}
            // onChange={(e: SelectOption) =>
            //   field.onChange(isMulti ? e : e.value)
            // }
            value={
              isMulti
                ? field.value
                : options.find((option) => option.value === field.value)
            }
            onChange={(selected: any) => {
              if (isMulti) {
                field.onChange(selected); // Para multi, `selected` já é uma lista
              } else {
                field.onChange((selected as SelectOption).value); // Para single, pegue o valor
              }
            }}
            // onChange={handleOnChange}
          />
        )}
      />
      {inputError && (
        <ErrorWrapper>
          <FiAlertCircle color={colors.error[100]} size="0.875rem" />
          <Trans>{inputError.message}</Trans>
        </ErrorWrapper>
      )}
    </Container>
  );
};

export default MultiSelect;
